import { createStore } from 'vuex'

export default createStore({
  state: {
    userInfo: null
  },
  getters: {
    getUserInfo(state) {
      console.log(JSON.parse(localStorage.getItem("userInfo")) ,'12313213113');
      return state.userInfo || JSON.parse(localStorage.getItem("userInfo")) || {}
    }
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data
      localStorage.setItem("userInfo", JSON.stringify(data))

    }
  },
  actions: {
    logOut(){
      localStorage.removeItem('userInfo')
      window.location.href = 'http://'+window.location.host
    }
  },
  modules: {
  }
})
