import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'
const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login/Login.vue'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/',
        name: 'OrderIndex',
        redirect: '/order',
        component: () => import('@/views/Order/OrderIndex.vue'),
        meta: {
            title: '订单',
        },
        children: [
            {
                path: '/order',
                name: 'order',
                component: () => import('@/views/Order/SiteOrder.vue'),
                meta: {
                    title: '订单',
                },
            },
            {
                path: '/order/OrderDetail',
                name: 'OrderDetail',
                component: () => import('@/views/Order/OrderDetail.vue'),
                meta: {
                    title: '订单详情',
                    isChild: true
                }
            },
            {
                path: '/order/CardInformation',
                name: 'CardInformation',
                component: () => import('@/views/Order/CardInformation.vue'),
                meta: {
                    title: '开卡信息',
                    isChild: true
                }
            },
            {
                path: '/order/OrderSuccess',
                name: 'OrderSuccess',
                component: () => import('@/views/Order/OrderSuccess.vue'),
                meta: {
                    title: '开卡成功',
                    isChild: true
                }
            },
        ]
    },
    {
        path: '/SiteList',
        name: 'site',
        redirect: '/site',
        component: () => import('@/views/Site/SiteIndex.vue'),
        meta: {
            title: '网点'
        },
        children: [
            {
                path: '/site',
                name: 'SiteList',
                component: () => import('@/views/Site/WebSite.vue'),
                meta: {
                    title: '网点',
                }
            },
            {
                path: '/site/AddSite',
                name: 'AddSite',
                component: () => import('@/views/Site/AddSite.vue'),
                meta: {
                    title: '添加网点',
                    isChild: true
                }
            },
            {
                path: '/site/SiteDetail',
                name: 'SiteDetail',
                component: () => import('@/views/Site/SiteDetail.vue'),
                meta: {
                    title: '网点详情',
                    isChild: true
                }
            },
            {
                path: '/site/QRCode',
                name: 'QRCode',
                component: () => import('@/views/Site/QRCode.vue'),
                meta: {
                    title: '二维码',
                    isChild: true
                }
            },
        ]
    },
    {
        path: '/income',
        name: 'income',
        component: () => import('@/views/Income/SiteIncome.vue'),
        meta: {
            title: '收入'
        }
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})
router.beforeEach((to, from, next) => {
    const ID = store.getters['getUserInfo'].ID
    if (ID) { // 判断是否有token
        if (to.path === '/login') {
            next({ path: '/login' });
        } else {
            next()
        }
    } else {
        if (to.path !== '/login') {
            next({ path: '/login' });
        } else {
            next()
        }
        // next({ path: '/login' }); // 否则全部重定向到登录页
    }
    return true
})
export default router
